require('../scss/style.scss');

(function () {
  const doc = document
  const rootEl = doc.documentElement
  const body = doc.body
  const lightSwitch = doc.getElementById('lights-toggle')
  /* global ScrollReveal */
  const sr = window.sr = ScrollReveal()

  rootEl.classList.remove('no-js')
  rootEl.classList.add('js')

  window.addEventListener('load', function () {
    body.classList.add('is-loaded')
  })

  // Reveal animations
  function revealAnimations () {
    sr.reveal('.feature', {
      duration: 600,
      distance: '20px',
      easing: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
      origin: 'right',
      viewFactor: 0.2
    })
  }

  if (body.classList.contains('has-animations')) {
    window.addEventListener('load', revealAnimations)
  }

  // Light switcher
  if (lightSwitch) {
    window.addEventListener('load', checkLights)
    lightSwitch.addEventListener('change', checkLights)
  }

  function checkLights () {

    var favicon = document.querySelector("link[rel~='icon']");
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.rel = 'icon';
      document.head.appendChild(favicon);
    }
    
    

    let labelText = lightSwitch.parentNode.querySelector('.label-text')
    if (lightSwitch.checked) {
      body.classList.remove('lights-off')
      if (labelText) {
        labelText.innerHTML = 'Светлая тема'
      }

      favicon.href = '/favicon-light.ico';
    } else {
      body.classList.add('lights-off')
      if (labelText) {
        labelText.innerHTML = 'Темная тема'
      }
      favicon.href = '/favicon-dark.ico';
    }
  }
}())

$(document).ready(function(){
  $('.scrollTo').on('click', function(e) {
    e.preventDefault();

    let href = $(this).attr('href');

    $('html, body').animate({
        scrollTop: $(href).offset().top
    }, {
        duration: 1000,   // по умолчанию «400» 
        easing: "linear" // по умолчанию «swing» 
    });

    return false;
  });

  $("#inquiry_form").submit(function(e){
    e.preventDefault();
    e.stopPropagation();

    const name = $("#inquiry_name").val();
    const email = $("#inquiry_email").val();

    const inquiry_url = "/landing/inquiry";

    $.post(inquiry_url, {
      name: name,
      email: email,
    }, function(){
      $('#inquiry_box').hide();
      $("#inauiry_success_box").show();
    });
  });
});